'use client';

import ReactDOM from 'react-dom';

export const PreloadResources = () => {
  // @ts-ignore
  ReactDOM.preconnect('https://a.storyblok.com');

  // @ts-ignore
  ReactDOM.preload('/font-faces/Montserrat-SemiBold.woff2', {
    as: 'font',
    crossOrigin: 'true',
    type: 'font/woff2',
  });

  return null;
};
